import { call, put, takeEvery } from "@redux-saga/core/effects";
import { SELECT_DISPENSARY, SUCCESS_DISPENSARY } from "../redux/types/types";
import {API, graphqlOperation} from "aws-amplify";
import { getDispensary, getDispensaryByDoctorPK } from "../../../graphql/queries";

function* getDis(action){
    try{
        console.log('payload',)
        const doctorPk = yield call( [API, 'graphql'], graphqlOperation(getDispensaryByDoctorPK, {'doctorPK':action.payload}));
        console.log(doctorPk)
        yield put ({type:SUCCESS_DISPENSARY, payload:doctorPk})
        console.log('payload',doctorPk)
    }
    catch(error){
        console.log(error);
    }
}

export function* watchFetchDispensarySaga(){

    yield takeEvery(SELECT_DISPENSARY,  getDis)
}