/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-south-1",
    "aws_cognito_identity_pool_id": "ap-south-1:b44b06ab-95d3-4a4a-b3c2-a350844240bb",
    "aws_cognito_region": "ap-south-1",
    "aws_user_pools_id": "ap-south-1_7XQXfPedW",
    "aws_user_pools_web_client_id": "26hg08msl3l5893cc6iftsn37p",
    "oauth": {}
};


export default awsmobile;
