/* eslint-disable */
// this is an auto generated file. This will be overwritten

// export const getDoctors = /* GraphQL */ `
//   query GetDoctors($id: ID!) {
//     getDispensary(id: $id) {
//       id
//       name
//       details {
//         items {
//           id
//           name
//           registrationNo
//           location
//           createdAt
//           updatedAt
//         }
//         nextToken
//       }
//       createdAt
//       updatedAt
//     }
//   }
// `;
export const getDoctors = /* GraphQL */ `
  query MyQuery($nextToken: String) {
  getDoctors(nextToken: $nextToken) {
    doctors {
      slmc_registratoin_number
      active
      availability
      first_name
      last_name
      verified
      speciality
      review
      rating
      preferred_doctor
      payment_method
      mobile_number
      initials
      image_url
      educations
      display_name
      created_date
      contact_number
      consultation_fee
      city
      PK
      SK
    }
    nextToken
  }
}
`;
export const getDispensaryByDoctorPK = /* GraphQL */ `
  query MyQuery($doctorPK: String) {
    getDispensaryByDoctorPK(doctorPK: $doctorPK) {
    posts{
      PK
      SK
    }
  }
}
`;
export const getDispensary = /* GraphQL */ `
  query MyQuery($dispensaryPK: String) {
    getDispensary(dispensaryPK: $dispensaryPK) {
      posts{
        PK
        SK
        address
        br_number
        card_reader
        contact_number
        geo_location {
          lat
          lon
        }
        name
        phone_number
        qr_img
        service_category
      }
    
  }
}
`;
export const getStaff = /* GraphQL */ `
  query GetStaff($id: ID!) {
    getStaff(id: $id) {
      id
      title
      blogID
      blog {
        id
        name
        posts {
          nextToken
        }
        createdAt
        updatedAt
      }
      
      createdAt
      updatedAt
    }
  }
`;

