import { SELECT_DISPENSARY, SUCCESS_DISPENSARY } from "../types/types";

const initialState = {
    pk_id:[],
    loading: false,
    error: null
}

export default function dispensarys(state = initialState, action){
    switch (action.type) {
        case SELECT_DISPENSARY:
            return{
                ...state, 
                pk_id:action.payload
            };
            break;
        case SUCCESS_DISPENSARY:
                return{
                    ...state, 
                    pk_id:action.payload
                };
                break;
        }
        return state 
    }