import React ,{useEffect, useState} from 'react'
import { connect } from 'react-redux'
import { Grid, Table,Header, Button } from 'semantic-ui-react'
import { bindActionCreators } from 'redux';
import{ Link} from 'react-router-dom';
import { API, graphqlOperation } from 'aws-amplify';
import {getDispensary,  getDispensaryByDoctorPK} from '../../graphql/queries';

function DoctorStaffDtails({SelectedDoctorReducer}) {
  const [dis, setDispensary] = useState([]);
  useEffect(()=>{
    DispensaryDetails();
},[]);
// useEffect(()=>{
//   DispensaryDoctor();
// },[]);
 const DispensaryDetails = async ()=>{
  try{
    const did = <>{SelectedDoctorReducer.PK}</>
    const pk = did.props.children;
    console.log('Id',pk)
    const selectDispansary = await API.graphql(graphqlOperation( getDispensaryByDoctorPK, {'doctorPK':pk}));
    const dispensary = selectDispansary.data.getDispensaryByDoctorPK.posts;
    setDispensary(dispensary);
    console.log('dispensary PK:', dispensary);
    const sk = dispensary[0].SK;
    const selectedDispensary = await API.graphql(graphqlOperation(getDispensary, {'dispensaryPK':sk}));
    const dispensary_by_id = selectedDispensary.data.getDispensary.posts;
    console.log('selected dispensary', dispensary_by_id);
    setDispensary(dispensary_by_id)
   }catch(error){
      console.log('error on dipensary details', error);
   }
 }
 console.log('dis', dis);
  if(SelectedDoctorReducer.length===0){
    return(
      <div >
        <h3 >No Doctor Here Go Back And Come Again</h3>
        <br>
        </br>
        <Link to="/"><Button  content='Go Back' color='blue' icon='right arrow' labelPosition='right' /></Link>
      </div>
      
    )
  }
  if(dis.length===0){
    return(
      <div >
        
      </div>
      
    )
  }
    return(
        <Grid divided='vertically'>
  <Table definition>  
    <Table.Header>
      <Table.Row>
        <Table.HeaderCell />
        <Table.HeaderCell>Details</Table.HeaderCell>
      </Table.Row>
    </Table.Header>

    <Table.Body>
      <Table.Row>
        <Table.Cell>Name</Table.Cell>
        <Table.Cell>{dis[0].name}</Table.Cell>
      </Table.Row>
      <Table.Row>
        <Table.Cell>Email</Table.Cell>
        <Table.Cell>{dis[0].phone_number}</Table.Cell>
      </Table.Row>
      <Table.Row>
        <Table.Cell>Name of Dispensary</Table.Cell>
        <Table.Cell>{dis[0].name}</Table.Cell>
      </Table.Row>
      <Table.Row>
        <Table.Cell>B.R Number</Table.Cell>
        <Table.Cell>{dis[0].br_number}</Table.Cell>
      </Table.Row>
      <Table.Row>
        <Table.Cell>Address</Table.Cell>
        <Table.Cell>{dis[0].address}</Table.Cell>
      </Table.Row>
      <Table.Row>
        <Table.Cell>Mobile</Table.Cell>
        <Table.Cell>{dis[0].contact_number}</Table.Cell>
      </Table.Row>
      <Table.Row>
        <Table.Cell>Service Category</Table.Cell>
        <Table.Cell>{dis[0].service_category}</Table.Cell>
      </Table.Row>
      <Table.Row>
        <Table.Cell>CC Medichine Availability</Table.Cell>
        <Table.Cell>{dis[0].card_reader}</Table.Cell>
      </Table.Row>
    </Table.Body>
  </Table>
          <Grid.Row columns={2}>
            <Grid.Column>
              <Header as='h3' block>
               Doctor Details
              </Header>
            <Table definition>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell />
              <Table.HeaderCell>Details</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
      
          <Table.Body>
            <Table.Row>
              <Table.Cell>Title</Table.Cell>
              <Table.Cell>{SelectedDoctorReducer.educations}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Initials</Table.Cell>
              <Table.Cell>{SelectedDoctorReducer.initials}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Last Name</Table.Cell>
              <Table.Cell>{SelectedDoctorReducer.last_name}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Display Name</Table.Cell>
              <Table.Cell>{SelectedDoctorReducer.display_name}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>SLMC Number</Table.Cell>
              <Table.Cell>{SelectedDoctorReducer.slmc_registratoin_number}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Speciality</Table.Cell>
              <Table.Cell>{SelectedDoctorReducer.speciality}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Doctor Fee</Table.Cell>
              <Table.Cell>Rs.{SelectedDoctorReducer.consultation_fee}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>If Not Dispensary Owner</Table.Cell>
              <Table.Cell>{SelectedDoctorReducer.initials}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Email</Table.Cell>
              <Table.Cell>{SelectedDoctorReducer.mobile_number}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Verify</Table.Cell>
              <Table.Cell>
              <Button positive >Active</Button>
              <Button negative>Reject</Button>
              </Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
            </Grid.Column>
            <Grid.Column>
            <Header as='h3' block>
               Staff Details
              </Header>
            <Table definition>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell />
              <Table.HeaderCell>Details</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
      
          <Table.Body>
            <Table.Row>
              <Table.Cell>Frist Name</Table.Cell>
              <Table.Cell>None</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Last Name</Table.Cell>
              <Table.Cell>rating (integer)</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Address</Table.Cell>
              <Table.Cell>rating (integer)</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Mobile</Table.Cell>
              <Table.Cell>rating (integer)</Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
            </Grid.Column>
          </Grid.Row>
        </Grid>
    )
  }

const mapStateToProps = state =>{
    return{
      SelectedDoctorReducer:state.SelectedDoctorReducer
    }
}


export default connect(mapStateToProps)(DoctorStaffDtails)
