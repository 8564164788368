import { ADD_DOCTOR_LIST, SUCCESS } from "../types/types";

const initialState = {
    doctors:[],
    loading: false,
    error: null
}

export default function doctors(state = initialState, action){
    switch (action.type) {
        case ADD_DOCTOR_LIST:
            return{
                ...state, 
                doctors:action.payload
            };
            break;
         case SUCCESS:
            return{
                ...state, 
                doctors:action.payload
            };   
    }
    return state 
}


