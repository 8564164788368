import { createStore,applyMiddleware,compose} from "redux";
import thunk from 'redux-thunk'
import { combineReducers } from "redux";
import createSagaMiddleware from 'redux-saga';
import rootReducers from "../components/dispensary/redux/reducers/rootReducerl";
import rootSaga from "../components/dispensary/sagas/rootSaga";
import reducer from "../components/dispensary/redux/reducers/doctors.reducers";


const sagaMiddleware = createSagaMiddleware()

const configureStore = createStore(
    rootReducers,
    compose(
        applyMiddleware(sagaMiddleware),
        window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
        )
)
sagaMiddleware.run(rootSaga);
export default configureStore