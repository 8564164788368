import { SELECT_DOCTOR, SELECT_DISPENSARY, SUCCESS_DISPENSARY, SUCCESS_DISPENSARY_DETAILS, SUCCESS_DISPENSARY_DETAILS_LIST} from "../types/types";
const initialState = {
    doctor:[],
    loading: true,
    error: null
}

export default function SelectedDoctorReducer(state=initialState, action){
    switch(action.type){
        case SELECT_DOCTOR:
            return action.payload;
            break;
        case 'SUCCESS_SELECT':
                return {
                    ...state,
                    doctor:action.payload};
                break;    
        case SELECT_DISPENSARY:
            return{
                ...state,
                doctor:action.payload
            }
            break;
        case SUCCESS_DISPENSARY:
            return{
                ...state,
                doctor:action.payload
            }
            break;
        case SUCCESS_DISPENSARY_DETAILS:
            return {
                ...state,
                doctor:action.payload
            }
            break;
        case SUCCESS_DISPENSARY_DETAILS_LIST:
            return {
                ...state,
                doctor:action.payload
            }
            break;
    }
    return state
}