import { MapEntries } from 'aws-amplify-react/lib-esm/AmplifyMessageMap';
import './Header.css';
import { AmplifySignOut } from '@aws-amplify/ui-react';
import Logo from '../core/logo/Logo';

function Header(){
    return(
        <div className="Header-main">
            <Logo/>
        </div>
    );
}

export default Header;