export const ADD_DOCTOR_LIST= 'ADD_DOCTOR_LIST';

export const SUCCESS = 'SUCCESS';

export const SELECT_DOCTOR = 'SELECT_DOCTOR';

export const SELECT_DISPENSARY ='SELECT_DISPENSARY';

export const SUCCESS_DISPENSARY = 'SUCCESS_DISPENSARY';

export const SUCCESS_DISPENSARY_DETAILS = 'SUCCESS_DISPENSARY_DETAILS';

export const SUCCESS_DISPENSARY_DETAILS_LIST = 'SUCCESS_DISPENSARY_DETAILS_LIST';