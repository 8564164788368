import {combineReducers} from 'redux';
import doctors  from './doctors.reducers';
import SelectedDoctorReducer from './reducerSelectDoctor';
import dispensarys from './dispensaryReducer';
const rootReducers = combineReducers({
    doctors:doctors,
    SelectedDoctorReducer:SelectedDoctorReducer,
    dispensarys:dispensarys

})

export default rootReducers;