import { call, put, takeEvery } from "@redux-saga/core/effects";
import { SELECT_DISPENSARY, SUCCESS_DISPENSARY_DETAILS } from "../redux/types/types";
import {API, graphqlOperation} from "aws-amplify";
import { getDispensary, getDispensaryByDoctorPK } from "../../../graphql/queries";

function* getSelectedDispensary(action){
    try{
        console.log('payload',)
        const dispensary_details = yield call( [API, 'graphql'], graphqlOperation(getDispensary, {'dispensaryPK':action.payload.doctorPK.SK}));
        console.log(dispensary_details)
        yield put ({type:SUCCESS_DISPENSARY_DETAILS, payload:dispensary_details})
        console.log('payload',dispensary_details)
    }
    catch(error){
        console.log(error);
    }
}

export function* watchFetchDispensaryDetailsSaga(){

    yield takeEvery(SUCCESS_DISPENSARY_DETAILS,  getSelectedDispensary)
}