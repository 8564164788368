import React from 'react';
import {useState} from 'react';
import './App.css';
import Amplify from 'aws-amplify';
import { AmplifyAuthenticator, AmplifySignOut } from '@aws-amplify/ui-react';
import { AuthState, onAuthUIStateChange } from '@aws-amplify/ui-components';
import awsconfig from './aws-exports';
import appSyncConfig from './appsync_config'
import Header from './components/header/Header';
import Button from './components/core/button/Button';
import{ BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import DoctorStaffDtails from './components/dispensary/DoctorStaffDtails'; 
import DispensaryDetails from './components/dispensary/DispensaryDetails';
import {Container, Segment, Statistics, Grid} from 'semantic-ui-react'
import { useSelector } from 'react-redux';
import DoctorView from './components/doctor/DoctorView';
import {createStore} from 'redux';
import Buttons from './components/buttons/Buttons';
Amplify.configure(awsconfig);
Amplify.configure(appSyncConfig);

const AuthStateApp = () => {
    const [authState, setAuthState] = React.useState();
    const [user, setUser] = React.useState();
    const doctors = useSelector(state => state.doctors)

    React.useEffect(() => {
        return onAuthUIStateChange((nextAuthState, authData) => {
            setAuthState(nextAuthState);
            setUser(authData)
        });
    }, []);

    

  return authState === AuthState.SignedIn && user ? (
      <Router>
           <Router>
               <Segment>
               <div > 
          <div className="App">

          {/* <h1>Header</h1> */}

              <div className="row">
                  <div className="col-md-3">
                  <Header/>
                  </div>
                  <div class="col-md-7">
                  <h1>Hello EZDOC Admin </h1>
                  </div>
                  <div class="col-md-2 ">
                  <Button/>
                  </div>
              </div>
          </div>
          {/* <h1>Body</h1> */}
          <div>
          <Router>
<Switch>
    <Route path="/" exact component={DoctorView}>
        <Segment>
         <DoctorView />
        </Segment>
    </Route>
    <Route path="/View_details" exact component={DispensaryDetails}></Route>
</Switch>
</Router>
          </div>     
      </div>
               </Segment>
          
      </Router>
      </Router>  
    ) : (
      <AmplifyAuthenticator />
  );
}

export default AuthStateApp;