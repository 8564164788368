import React from 'react'
import { Table, Dropdown, Button, Segment, Grid } from 'semantic-ui-react'
import{ Link} from 'react-router-dom';
import Buttons from '../buttons/Buttons';
import { connect, useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import { SelectedDoctorReducer } from '../dispensary/redux/action/doctors.action';

const options = [
  { key: 1, text: 'YES', value: 1 },
  { key: 2, text: 'NO', value: 2 },
]



const DoctorView = ({doctors}) =>{
  const selecteddispatch = useDispatch()
  console.log(doctors)
  if(doctors.length===0){
    return(
      <div >
        <h3 >Click Button Bellow Get Doctors List</h3>
        <br>
        </br>
        <Buttons/>
      </div>
      
    )
  }
  return(
    <Grid.Column divided='vertically'>
      <Table celled padded>
    <Table.Header>
      <Table.Row>
        <Table.HeaderCell>Doctor Name</Table.HeaderCell>
        <Table.HeaderCell>Contact Number</Table.HeaderCell>
        {/* <Table.HeaderCell>Email</Table.HeaderCell> */}
        <Table.HeaderCell>Verification Status</Table.HeaderCell>
        <Table.HeaderCell>Active more than one month</Table.HeaderCell>
        <Table.HeaderCell>Inactivate Reason</Table.HeaderCell>
        <Table.HeaderCell>Initial call</Table.HeaderCell>
        <Table.HeaderCell>Initial Email</Table.HeaderCell>
        <Table.HeaderCell>Contact Provided by</Table.HeaderCell>
        <Table.HeaderCell>Action</Table.HeaderCell>
      </Table.Row>
    </Table.Header>
    <Table.Body>
      {doctors.map((doctor)=>{
        var msDiff = new Date().getTime() - new Date(doctor.created_date).getTime();    //Future date - current date
        var daysTill = Math.floor(msDiff / (1000 * 60 * 60 * 24));
        console.log(daysTill);
        let status;
       if(daysTill>30){
                    status = <h4>Yes</h4>;
        }
        else{
            status = <h4>No</h4>;
        }
        
        return(
        <Table.Row key={doctor.PK}>
          <Table.Cell>{doctor.first_name}</Table.Cell>
          <Table.Cell >{doctor.contact_number}</Table.Cell>
          {/* <Table.Cell>{doctor.mobile_number}</Table.Cell> */}
          <Table.Cell >{doctor.active}</Table.Cell>
          <Table.Cell>{status}</Table.Cell>
          <Table.Cell>{doctor.inactivate_reason}</Table.Cell>
          <Table.Cell ><Dropdown size='mini' clearable options={options} selection /></Table.Cell>
          <Table.Cell><Dropdown size='mini' clearable options={options} selection /></Table.Cell>
          <Table.Cell ><Dropdown size='mini' clearable options={options} selection /></Table.Cell>
          <Table.Cell><Link to="/View_details"><Button key={doctor.PK} onClick={()=>selecteddispatch(SelectedDoctorReducer(doctor))} content='View' color='blue' icon='right arrow' labelPosition='right' /></Link></Table.Cell>
        </Table.Row>
        )
        
      })}
      
    </Table.Body>
  </Table>
  </Grid.Column>
  )
  
}



const mapStateToProps = state =>{
  return{
      doctors:state.doctors.doctors
  }
}
const mapchDispatchToProps = dispatch =>{
  return bindActionCreators({SelectedDoctorReducer:SelectedDoctorReducer}, dispatch)
}

export default connect(mapStateToProps,mapchDispatchToProps)(DoctorView)