import {all, call, take } from 'redux-saga/effects';
import {watchFetchDataSaga} from './doctorsSaga';
import { watchFetchDispensarySaga } from './dispensarySaga';
import { watchFetchDispensaryDetailsSaga } from './selected_dispensary_saga';

export default function* rootSaga(){
    yield all([
        watchFetchDataSaga(),
        watchFetchDispensarySaga(),
        watchFetchDispensaryDetailsSaga(),
    ])
}