import React from 'react'
import { Button } from 'semantic-ui-react'
import { addDoctorRedux } from '../dispensary/redux/action/doctors.action';
import { connect, useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';

function Buttons () {
  const dispatch  = useDispatch()
  return (
    <div>
        <Button fluid onClick={()=>dispatch(addDoctorRedux())}>Get Doctors List</Button>
    </div>
  )
}
function matchDispatchToProps(dispatch){
  return bindActionCreators({addDoctorRedux: addDoctorRedux},dispatch)
}

export default Buttons;
