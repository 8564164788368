const appSyncConfig = {
    // ...
    'aws_appsync_graphqlEndpoint': 'https://oavfjkim4ncn7iqebgpt2iutra.appsync-api.ap-south-1.amazonaws.com/graphql',
    'aws_appsync_region': 'ap-south-1',
    'aws_appsync_authenticationType': 'API_KEY',
    'aws_appsync_apiKey': 'da2-axfvz24ytzhm7maha2mzwntml4',
    // ...
}

export default appSyncConfig;
