import './Logo.css';
import logo from './logo.png';

function Logo(){
    return(
        <img className="Logo-main" src={logo} alt="EZDOC"/>
    );

}

export default Logo;