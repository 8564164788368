import * as type from '../types/types';

export const addDoctorRedux = () => {
    return {
        type:type.ADD_DOCTOR_LIST,
        payload:[]
    }
   
}
// export const success =()=>{
//     return
// }
// export function addDoctorRedux(){
//     return function(dispatch){
//         axios.get("https://reqres.in/api/users/")
//         .then(res=>{
//             console.log(res)
//             dispatch({
//                 type: type.ADD_DOCTOR_LIST,
//                 payload:res.data
//             })
//         })
//     }
// }
export const SelectedDoctorReducer= (doctor) =>{
    return {
        type: type.SELECT_DOCTOR,
        payload:doctor
    }
};

export const dispensary = (PK)=>{
    return {
        type: type.SELECT_DISPENSARY,
        payload:PK
    }
};