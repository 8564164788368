import { call, put, takeEvery } from "@redux-saga/core/effects";
import {ADD_DOCTOR_LIST,SUCCESS} from '../redux/types/types';
import axios from 'axios';
import { API, graphqlOperation } from "aws-amplify";
import {getDoctors} from '../../../graphql/queries.js';

function* getDoctor(action){
    console.log('try', action)
    try{
        const doctorData = yield call( [API, 'graphql'], graphqlOperation(getDoctors));
        const doctorList = doctorData.data.getDoctors.doctors;
        yield put({type:SUCCESS, payload:doctorList})
        console.log(doctorList);
    }
    catch(error){
        console.log( error);
    }
}

export function* watchFetchDataSaga(){

    yield takeEvery(ADD_DOCTOR_LIST,  getDoctor)
}


