import './Button.css';
import { AmplifySignOut } from '@aws-amplify/ui-react';

function Button(){
    return(
        <div className="Btn">
                <AmplifySignOut/>
        </div>
    );
}
export default Button;