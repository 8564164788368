import React from 'react'
import { Table, Header, Segment } from 'semantic-ui-react'
import DoctorStaffDtails from './DoctorStaffDtails'

function DispensaryDetails (){

 

    return(
        <Segment>
         <Header as='h3' block >
         Dispensary Details
        </Header>
        <Segment>
            <DoctorStaffDtails DispensaryDetails={DispensaryDetails}/>
        </Segment>
    </Segment>
    )    
}

export default DispensaryDetails

